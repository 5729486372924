import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/AdminDashboard.css"
import admin from "../assets/Images/buttonIcons/admin.svg";
import knowledge from "../assets/Images/buttonIcons/knowledge.svg";
import trackers from "../assets/Images/buttonIcons/track.svg";
import push from "../assets/Images/buttonIcons/push.svg";
import playerTroops from "../assets/Images/buttonIcons/playerTroops.svg";
import InteractiveMap from "../assets/Images/buttonIcons/map.svg";
import player from "../assets/Images/buttonIcons/player.svg";
import playerTools from "../assets/Images/buttonIcons/playertools.svg";
import { useSelector } from "react-redux";

const HomeUser = () => {
  const { user } = useSelector((state) => state.auth);
  const [role, setRole] = useState();

  useEffect(() => {
    if (user) {
      setRole(user?.role);
    }
  }, [user]);

  return (
    <div className="mt-4 px-5 container-fluid button-card">
      {role === "Administrator" && (
        <Link className="btn btn-primary mt-2 ms-3 button-link" to="/admin">
          <div className="button-icon">
            <img src={admin} className="img-fluid" alt="Admin" />
          </div>
          <p>Admin</p>
        </Link>
      )}
      <Link
        className="btn btn-primary mt-2 ms-3 button-link"
        to="/knowledge-base"
      >
        <div className="button-icon">
          <img src={knowledge} className="img-fluid" alt="Knowledge Base" />
        </div>
        <p>Knowledge Base</p>
      </Link>
      <Link className="btn btn-primary mt-2 ms-3 button-link" to="/trackers">
        <div className="button-icon">
          <img src={trackers} className="img-fluid" alt="Trackers" />
        </div>
        <p>Trackers</p>
      </Link>
      <Link className="btn btn-primary mt-2 ms-3 button-link" to="/pushes">
        <div className="button-icon">
          <img src={push} className="img-fluid" alt="Pushes" />
        </div>
        <p>Pushes</p>
      </Link>
      <Link className="btn btn-primary mt-2 ms-3 button-link" to="/player-tools">
        <div className="button-icon">
          <img src={playerTools} className="img-fluid" alt="Player Tools" />
        </div>
        <p>Player Tools</p>
      </Link>
      <Link
        className="btn btn-primary mt-2 ms-3 button-link"
        to="/player-roster"
      >
        <div className="button-icon">
          <img src={player} className="img-fluid" alt="Player Roster" />
        </div>
        <p>Player Roster</p>
      </Link>
      <Link
        className="btn btn-primary mt-2 ms-3 button-link"
        to="/player-troops"
      >
        <div className="button-icon">
          <img src={playerTroops} className="img-fluid" alt="Player Troops" />
        </div>
        <p>Player Troops</p>
      </Link>
      <Link
        className="btn btn-primary mt-2 ms-3 button-link"
        to="/interactive-map"
      >
        <div className="button-icon">
          <img src={InteractiveMap} className="img-fluid" alt="Interactive Map" />
        </div>
        <p>Interactive Map</p>
      </Link>
    </div>
  );
};

export default HomeUser;
