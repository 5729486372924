import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Spinner,
} from "reactstrap";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMapCoordinates,
  fetchMapData,
} from "../../store/actions/mapAction";
import TileDetailModal from "./TileDetailModal";

const CustomMap = () => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const canvasRef = useRef(null);
  const [scale, setScale] = useState(0.1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredBox, setHoveredBox] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [serverUrl, setServerUrl] = useState(null);
  const [favouriteModalIsOpen, setFavouriteModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const [mapImage, setMapImage] = useState(null);
  const [mapCoordinatesData, setMapCoordinatesData] = useState(null);
  const [clickedBox, setClickedBox] = useState({ x: 0, y: 0 });
  const [xCoordinate, setXCoordinate] = useState(null);
  const [yCoordinate, setYCoordinate] = useState(null);

  const toggleFavouriteModal = () =>
    setFavouriteModalIsOpen(!favouriteModalIsOpen);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleZoomChange = (zoomLevel) => {
    setScale(zoomLevel);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

  const handleWheel = (event) => {
    event.preventDefault();
    const scaleAmount = 0.1;
    const newScale = clamp(
      scale + (event.deltaY > 0 ? -scaleAmount : scaleAmount),
      0.1,
      1
    );
    setScale(newScale);
    const canvas = canvasRef.current;
    const mapWidth = 400 * 40 * newScale;
    const mapHeight = 400 * 40 * newScale;
    const rect = canvas.getBoundingClientRect();
    const maxOffsetX = mapWidth / 2 - rect.width / 2;
    const maxOffsetY = mapHeight / 2 - rect.height / 2;
    setOffset({
      x: clamp(offset.x, -maxOffsetX, maxOffsetX),
      y: clamp(offset.y, -maxOffsetY, maxOffsetY),
    });
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStart({ x: event.clientX - offset.x, y: event.clientY - offset.y });
    setHasDragged(false);
  };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    if (isDragging) {
      const mapWidth = 400 * 40 * scale;
      const mapHeight = 400 * 40 * scale;
      const maxOffsetX = mapWidth / 2 - rect.width / 2;
      const maxOffsetY = mapHeight / 2 - rect.height / 2;
      const newOffsetX = event.clientX - dragStart.x;
      const newOffsetY = event.clientY - dragStart.y;
      setOffset({
        x: clamp(newOffsetX, -maxOffsetX, maxOffsetX),
        y: clamp(newOffsetY, -maxOffsetY, maxOffsetY),
      });
      setHasDragged(true);
    }
    const x = event.clientX - rect.left - canvas.width / 2 - offset.x;
    const y = event.clientY - rect.top - canvas.height / 2 - offset.y;
    const gridSize = 40;
    const boxX = Math.floor(x / gridSize / scale) * gridSize;
    const boxY = Math.floor(y / gridSize / scale) * gridSize;
    setHoveredBox({ x: boxX / gridSize, y: -boxY / gridSize });
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  const handleClick = async (event) => {
    if (hasDragged) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left - canvas.width / 2 - offset.x;
    const y = event.clientY - rect.top - canvas.height / 2 - offset.y;
    const gridSize = 40;
    const boxX = Math.floor(x / gridSize / scale) * gridSize;
    const boxY = Math.floor(y / gridSize / scale) * gridSize;
    const coordinates = await dispatch(
      fetchMapCoordinates({ x: boxX / gridSize, y: -boxY / gridSize })
    );
    setMapCoordinatesData(coordinates);
    setClickedBox({ x: boxX / gridSize, y: -boxY / gridSize });
    setModalIsOpen(true);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => {
      if (prevScale === 0.1) return 0.5;
      if (prevScale === 0.5) return 1;
      return prevScale;
    });
  };

  const handleZoomOut = () => {
    setScale((prevScale) => {
      if (prevScale === 1) return 0.5;
      if (prevScale === 0.5) return 0.1;
      return prevScale;
    });
  };

  const getVisibleAreaCoordinates = () => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const topLeftX = (-offset.x - rect.width / 2) / scale;
    const topLeftY = (-offset.y - rect.height / 2) / scale;
    const bottomRightX = (-offset.x + rect.width / 2) / scale;
    const bottomRightY = (-offset.y + rect.height / 2) / scale;
    const topLeft = {
      x: Math.floor(topLeftX / 40),
      y: Math.floor(-topLeftY / 40),
    };
    const bottomRight = {
      x: Math.ceil(bottomRightX / 40),
      y: Math.ceil(-bottomRightY / 40),
    };
    return { topLeft, bottomRight };
  };

  const convertToVisibleArea = (visibleArea) => {
    return {
      xMin: visibleArea.topLeft.x,
      yMin: visibleArea.bottomRight.y,
      xMax: visibleArea.bottomRight.x,
      yMax: visibleArea.topLeft.y,
    };
  };

  const handleOkClick = async () => {
    const visibleArea = {
      topLeft: {
        x: Math.max(-200, xCoordinate - 50),
        y: Math.min(200, yCoordinate + 50),
      },
      bottomRight: {
        x: Math.min(200, xCoordinate + 50),
        y: Math.max(-200, yCoordinate - 50),
      },
    };
    const boundaries = {
      xMin: visibleArea.topLeft.x,
      yMin: visibleArea.bottomRight.y,
      xMax: visibleArea.bottomRight.x,
      yMax: visibleArea.topLeft.y,
    };
    const blob = await fetchMapData(boundaries);
    const image = new Image();
    const objectURL = URL.createObjectURL(blob);
    image.src = objectURL;

    image.onload = () => {
      setMapImage(image);
      URL.revokeObjectURL(objectURL);
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const drawGrid = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.translate(canvas.width / 2 + offset.x, canvas.height / 2 + offset.y);
      ctx.scale(scale, scale);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);
      if (mapImage) {
        const imageX = -200 * 40 + canvas.width / 2;
        const imageY = -200 * 40 + canvas.height / 2;
        const imageWidth = 400 * 40;
        const imageHeight = 400 * 40;

        ctx.drawImage(mapImage, imageX, imageY, imageWidth, imageHeight);
      }

      // const gridSize = 40;
      // ctx.strokeStyle = "gray";
      // ctx.fillStyle = "#bde68c";
      // ctx.lineWidth = 1;

      // for (let x = -200 * gridSize; x <= 200 * gridSize; x += gridSize) {
      //   for (let y = -200 * gridSize; y <= 200 * gridSize; y += gridSize) {
      //     ctx.beginPath();
      //     ctx.rect(
      //       x + canvas.width / 2,
      //       y + canvas.height / 2,
      //       gridSize,
      //       gridSize
      //     );
      //     ctx.stroke();
      //     ctx.fill();
      //   }
      // }

      ctx.restore();
    };

    drawGrid();
  }, [scale, offset, mapImage]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setServerUrl(data?.serverUrl);
    }
  }, [serverSettingsData]);

  const fetchVisibleArea = async () => {
    try {
      const visibleArea = getVisibleAreaCoordinates();
      const convertedArea = convertToVisibleArea(visibleArea);
      const blob = await fetchMapData(convertedArea);
      const image = new Image();
      const objectURL = URL.createObjectURL(blob);
      image.src = objectURL;
      image.onload = () => {
        setMapImage(image);
        URL.revokeObjectURL(objectURL);
      };
    } catch (error) {
      console.error("Error fetching map data:", error);
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchVisibleArea();
    }, 3000);

    return () => clearTimeout(debounceTimeout);
  }, [scale, offset]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-start">
      <div className="mb-2 d-flex gap-2">
        <Button color="primary" onClick={handleZoomIn} className="px-3">
          +
        </Button>
        <Button color="primary" onClick={handleZoomOut} className="px-3">
          -
        </Button>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          className="mx-2"
        >
          <DropdownToggle caret color="primary">
            Zoom
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleZoomChange(0.1)}>
              10%
            </DropdownItem>
            <DropdownItem onClick={() => handleZoomChange(0.5)}>
              50%
            </DropdownItem>
            <DropdownItem onClick={() => handleZoomChange(1)}>
              100%
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="d-flex">
        <div className="position-relative">
          {!mapImage && (
            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75 z-10">
              <Spinner color="primary" />
            </div>
          )}
          <canvas
            ref={canvasRef}
            width={600}
            height={600}
            className="m-2"
            style={{
              border: "2px solid black",
              cursor: isDragging ? "grabbing" : "grab",
            }}
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onClick={handleClick}
          />
          <div
            className="position-absolute bg-black text-white p-2 rounded"
            style={{ bottom: "20px", right: "15px" }}
          >
            <div>
              <label htmlFor="x-coordinate">X:</label>
              <input
                id="x-coordinate"
                type="number"
                value={xCoordinate}
                onChange={(e) => setXCoordinate(Number(e.target.value))}
                style={{ width: "50px", marginRight: "5px" }}
              />
              <label htmlFor="y-coordinate">Y:</label>
              <input
                id="y-coordinate"
                type="number"
                value={yCoordinate}
                onChange={(e) => setYCoordinate(Number(e.target.value))}
                style={{ width: "50px", marginRight: "5px" }}
              />
              <Button color="success" onClick={handleOkClick}>
                OK
              </Button>
            </div>
          </div>
        </div>
        <div>
          <h5>Favorite</h5>
          <Button color="primary" onClick={toggleFavouriteModal}>
            +
          </Button>
        </div>
      </div>
      {/* Hovered Box  */}
      {hoveredBox && (
        <div
          className="py-2 px-4 position-absolute text-white rounded"
          style={{
            top: mousePosition.y + 10 + "px",
            left: mousePosition.x + 10 + "px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <div>X: {hoveredBox.x}</div>
          <div>Y: {hoveredBox.y}</div>
        </div>
      )}
      {/* Tile detail Modal  */}
      <TileDetailModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        clickedBox={clickedBox}
        mapCoordinatesData={mapCoordinatesData}
        serverUrl={serverUrl}
      />
      {/* Favourite Modal  */}
      <Modal
        isOpen={favouriteModalIsOpen}
        toggle={toggleFavouriteModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={toggleFavouriteModal}>Add Coordinates</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label>Name:</label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>X Coordinate:</label>
            <Input
              type="number"
              value={x}
              onChange={(e) => setX(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>Y Coordinate:</label>
            <Input
              type="number"
              value={y}
              onChange={(e) => setY(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleFavouriteModal}>
            Save
          </Button>
          <Button color="secondary" onClick={toggleFavouriteModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomMap;
