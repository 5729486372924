import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const saveServerSettings = (
  data,
  documentId,
  docId,
  onSuccess = () => {}
) => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));
      const baseUrl = data.serverUrl.split("/").slice(0, 3).join("/");

      const formattedData = {
        ...data,
        serverUrl: baseUrl,
        startDate: firebase.firestore.Timestamp.fromDate(
          new Date(data.startDate)
        ),
      };

      const updateData = {
        serverUrl: baseUrl,
        serverName: data.serverName,
        userName: data.scraperUsername,
        password: data.scraperPassword,
      };

      if (documentId) {
        await firebase
          .firestore()
          .collection("serverSettings")
          .doc(documentId)
          .set(formattedData);
      } else {
        await firebase
          .firestore()
          .collection("serverSettings")
          .add(formattedData);
      }

      if (docId) {
        await firebase
          .firestore()
          .collection("serverMapInformation")
          .doc(docId)
          .update(updateData);
      } else {
        await firebase
          .firestore()
          .collection("serverMapInformation")
          .add(updateData);
      }
      onSuccess();
      toast.success("Server Settings data updated!");
      dispatch(loader(false));
    } catch (error) {
      console.error("Error saving data:", error);
      dispatch(loader(false));
      toast.error("Error saving Server Settings data");
    }
  };
};

export const saveScrapingCoordinates = (
  data,
  documentId,
  isMapBoundaries = false,
  onSuccess = () => {}
) => {
  return async (dispatch) => {
    try {
      dispatch(loader(true));

      const boundariesKey = isMapBoundaries ? "mapBoundaries" : "boundaries";

      const updateData = {
        [boundariesKey]: {
          xMin: data.xMin || null,
          xMax: data.xMax || null,
          yMin: data.yMin || null,
          yMax: data.yMax || null,
        },
      };

      if (documentId) {
        await firebase
          .firestore()
          .collection("serverMapInformation")
          .doc(documentId)
          .update(updateData);
      } else {
        await firebase
          .firestore()
          .collection("serverMapInformation")
          .add(updateData);
      }

      onSuccess();
      dispatch(loader(false));
    } catch (error) {
      console.error("Error saving data:", error);
      dispatch(loader(false));
      toast.error("Error saving Server Settings data");
    }
  };
};

export const getServerSettingsData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("serverSettings")
      .get();
    const serverSettingsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch({
      type: "SAVE_SERVER_SETTINGS_DATA",
      payload: serverSettingsData,
    });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching and storing Server Settings data:", error);
  }
};

export const getServerMapData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("serverMapInformation")
      .get();
    const serverMapInformation = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch({
      type: "SAVE_SERVER_MAP_DATA",
      payload: serverMapInformation,
    });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching and storing Server Settings data:", error);
  }
};

export const deleteServerData =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(loader(true));
    try {
      await firebase.firestore().collection("serverSettings").doc(id).delete();
      dispatch({ type: "DELETE_SERVER_SETTINGS_DATA", payload: id });
      toast.success("Server Settings Data deleted!");
      onSuccess();
      dispatch(loader(false));
      dispatch(getServerSettingsData());
    } catch (error) {
      console.error("Error deleting Data:", error);
      toast.error("Error deleting Server Settings data");
    }
  };

export const resetAllGameData =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const collections = [
        "hammerTracker",
        "defenderTrackerRows",
        "defenderTrackerTable",
        "defenderTrackerColumns",
        "playerRosterSavedData",
        "serverSettings",
        "allianceBonus",
        "resourcePush",
        "pushReport",
        "playerTroop",
        "webHooks",
        "latestMapInformation",
        "timeseriesMapInformation",
        "playersDataHistory",
        "allianceDataHistory",
        "villageStats",
      ];

      const deletePromises = [];

      for (const collectionName of collections) {
        const querySnapshot = await firebase
          .firestore()
          .collection(collectionName)
          .get();
        querySnapshot.forEach((doc) => {
          if (
            collectionName === "defenderTrackerColumns" &&
            doc.data().isDynamic === true
          ) {
            deletePromises.push(doc.ref.delete());
          } else if (collectionName !== "defenderTrackerColumns") {
            deletePromises.push(doc.ref.delete());
          }
        });
      }

      await Promise.all(deletePromises);
      onSuccess();
      dispatch(getServerSettingsData());
      toast.success("Game data has been reset successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Error reseting Game data");
    }
  };

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "SERVER_SETTINGS_LOADING",
    payload: val,
  });
};
