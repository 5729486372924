import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import CustomMap from "../components/InteractiveMap/Map";
import debounce from "lodash.debounce";
import typesenseServer from "../config/typesenseConfig";
import axios from "axios";
import { toast } from "react-toastify";
import {
  getAllianceVillages,
  getPlayersVillages,
} from "../store/actions/mapAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

const InteractiveMap = () => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [villagesModalOpen, setVillagesModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [playerSearchTerm, setPlayerSearchTerm] = useState("");
  const [villageSearchTerm, setVillageSearchTerm] = useState("");
  const [alliances, setAlliances] = useState([]);
  const [filteredAlliances, setFilteredAlliances] = useState([]);
  const [selectedAlliance, setSelectedAlliance] = useState(null);
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [villages, setVillages] = useState([]);
  const [filteredVillages, setFilteredVillages] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState(null);
  const [timezone, setTimeZone] = useState();
  const [coordinates, setCoordinates] = useState([
    { x: "", y: "" },
    { x: "", y: "" },
    { x: "", y: "" },
    { x: "", y: "" },
  ]);

  const todayUnix = moment.tz(timezone).startOf("day").unix();

  const fetchAlliances = async () => {
    try {
      const response = await axios.get(
        `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
        {
          params: {
            q: "*",
            query_by: "allianceTag",
            per_page: 250,
          },
          headers: {
            "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      const uniqueAllianceTags = Array.from(
        new Set(response.data.hits.map((hit) => hit.document.allianceTag))
      );
      setAlliances(uniqueAllianceTags);
      setFilteredAlliances(uniqueAllianceTags);
    } catch (error) {
      toast.error("Typesense search error:", error);
    }
  };

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(
        `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
        {
          params: {
            q: "*",
            query_by: "playerName",
            per_page: 250,
          },
          headers: {
            "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      const uniquePlayerNames = Array.from(
        new Set(response.data.hits.map((hit) => hit.document.playerName))
      );
      setPlayers(uniquePlayerNames);
      setFilteredPlayers(uniquePlayerNames);
    } catch (error) {
      toast.error("Typesense search error:", error);
    }
  };

  const fetchVillages = async () => {
    try {
      const response = await axios.get(
        `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
        {
          params: {
            q: "*",
            query_by: "villageName",
            per_page: 250,
          },
          headers: {
            "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
            "Content-Type": "application/json",
          },
        }
      );

      const uniqueVillagesNames = Array.from(
        new Set(response.data.hits.map((hit) => hit.document.villageName))
      );

      setVillages(uniqueVillagesNames);
      setFilteredVillages(uniqueVillagesNames);
    } catch (error) {
      toast.error("Typesense search error:", error);
    }
  };

  const handleAllianceSearch = useCallback(
    debounce(async (inputValue) => {
      if (inputValue) {
        const filtered = alliances.filter((alliance) =>
          alliance.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredAlliances(filtered);
      } else {
        setFilteredAlliances(alliances);
      }
    }, 300),
    [alliances]
  );

  const handlePlayerSearch = useCallback(
    debounce(async (inputValue) => {
      if (inputValue) {
        const filtered = players.filter((player) =>
          player.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredPlayers(filtered);
      } else {
        setFilteredPlayers(players);
      }
    }, 300),
    [players]
  );
  const handleVillageSearch = useCallback(
    debounce(async (inputValue) => {
      if (inputValue) {
        const filtered = villages.filter((village) =>
          village.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredVillages(filtered);
      } else {
        setFilteredVillages(villages);
      }
    }, 300),
    [villages]
  );

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleFilterModal = () => {
    if (!filterModalOpen) {
      fetchAlliances();
    }
    setFilterModalOpen(!filterModalOpen);
  };

  const togglePlayerModal = () => {
    if (!playerModalOpen) {
      fetchPlayers();
    }
    setPlayerModalOpen(!playerModalOpen);
  };

  const toggleVillageModal = () => {
    if (!villagesModalOpen) {
      fetchVillages();
    }
    setVillagesModalOpen(!villagesModalOpen);
  };

  const handleCoordinateChange = (index, axis, value) => {
    const numberValue = Number(value);
    const newCoordinates = [...coordinates];
    newCoordinates[index][axis] = numberValue;
    setCoordinates(newCoordinates);
  };

  const handleSubmit = () => {
    console.log("Submitted Coordinates:", coordinates);
    toggleModal();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    handleAllianceSearch(e.target.value);
  };

  const handlePlayerSearchChange = (e) => {
    setPlayerSearchTerm(e.target.value);
    handlePlayerSearch(e.target.value);
  };

  const handleAllianceSelect = async (alliance) => {
    setSelectedAlliance(alliance);
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  const handleVillageSelect = (player) => {
    setSelectedVillage(player);
  };

  const clearFilter = () => {
    setSelectedAlliance(null);
    setSearchTerm("");
    setFilteredAlliances(alliances);
  };

  const clearPlayerFilter = () => {
    setSelectedPlayer(null);
    setPlayerSearchTerm("");
    setFilteredPlayers(players);
  };

  const clearVillageFilter = () => {
    setSelectedVillage(null);
    setVillageSearchTerm("");
    setFilteredVillages(villages);
  };

  const handleAlliancesVillages = async () => {
    if (selectedAlliance) {
      const filteredVillages = await dispatch(
        getAllianceVillages(selectedAlliance, todayUnix)
      );
    }
  };

  const handlePlayerVillages = async () => {
    if (selectedPlayer) {
      const filteredVillages = await dispatch(
        getPlayersVillages(selectedPlayer, todayUnix)
      );
    }
  };

  const handleFilterVillage = async () => {
    if (selectedVillage) {
      console.log("selectedVillage: ", selectedVillage);
    }
  };

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
    }
  }, [serverSettingsData]);

  return (
    <>
      <div className="d-flex justify-content-center flex-wrap gap-3 mt-4 mb-4">
        <Button
          className="text-white p-2"
          color="info"
          onClick={toggleModal}
          type="button"
        >
          Set Alliance Control Zone
        </Button>
        <Button
          className="text-white p-2"
          color="info"
          onClick={toggleFilterModal}
          type="button"
        >
          Filter Alliances
        </Button>
        <Button
          className="text-white p-2"
          color="info"
          onClick={togglePlayerModal}
          type="button"
        >
          Filter Players
        </Button>
        <Button
          className="text-white p-2"
          color="info"
          type="button"
          onClick={toggleVillageModal}
        >
          Filter Villages
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        <CustomMap
          coordinates={coordinates}
          selectedAlliance={selectedAlliance}
          selectedPlayer={selectedPlayer}
        />
      </div>

      {/* Set Alliance Control Zone Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          Set Alliance Control Zone
        </ModalHeader>
        <ModalBody>
          {coordinates.map((coordinate, index) => (
            <div key={index} className="mb-3">
              <h6>Point {index + 1}</h6>
              <Input
                type="number"
                placeholder="X"
                value={coordinate.x}
                onChange={(e) =>
                  handleCoordinateChange(index, "x", e.target.value)
                }
                className="mb-2"
              />
              <Input
                type="number"
                placeholder="Y"
                value={coordinate.y}
                onChange={(e) =>
                  handleCoordinateChange(index, "y", e.target.value)
                }
              />
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      {/* Filter Alliances Modal */}
      <Modal isOpen={filterModalOpen} toggle={toggleFilterModal} centered>
        <ModalHeader toggle={toggleFilterModal}>Filter Alliances</ModalHeader>
        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Input
            type="text"
            placeholder="Search alliances..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="mb-3"
          />
          <ListGroup>
            {filteredAlliances.map((alliance, index) => (
              <ListGroupItem
                key={index}
                onClick={() => handleAllianceSelect(alliance)}
                active={selectedAlliance === alliance}
                style={{ cursor: "pointer" }}
              >
                {alliance}
              </ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={clearFilter}>
            Clear Filter
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleFilterModal();
              handleAlliancesVillages();
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </Modal>

      {/* Filter Players Modal */}
      <Modal isOpen={playerModalOpen} toggle={togglePlayerModal} centered>
        <ModalHeader toggle={togglePlayerModal}>Filter Players</ModalHeader>
        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Input
            type="text"
            placeholder="Search players..."
            value={playerSearchTerm}
            onChange={handlePlayerSearchChange}
            className="mb-3"
          />
          <ListGroup>
            {filteredPlayers.map((player, index) => (
              <ListGroupItem
                key={index}
                onClick={() => handlePlayerSelect(player)}
                active={selectedPlayer === player}
                style={{ cursor: "pointer" }}
              >
                {player}
              </ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={clearPlayerFilter}>
            Clear Filter
          </Button>
          <Button
            color="primary"
            onClick={() => {
              togglePlayerModal();
              handlePlayerVillages();
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </Modal>

      {/* Filter Villages Modal */}
      <Modal isOpen={villagesModalOpen} toggle={toggleVillageModal} centered>
        <ModalHeader toggle={toggleVillageModal}>Filter Villages</ModalHeader>
        <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Input
            type="text"
            placeholder="Search Village..."
            value={villageSearchTerm}
            onChange={handleVillageSearch}
            className="mb-3"
          />
          <ListGroup>
            {filteredVillages.map((village, index) => (
              <ListGroupItem
                key={index}
                onClick={() => handleVillageSelect(village)}
                active={selectedVillage === village}
                style={{ cursor: "pointer" }}
              >
                {village}
              </ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={clearVillageFilter}>
            Clear Filter
          </Button>
          <Button
            color="primary"
            onClick={() => {
              toggleVillageModal();
              handleFilterVillage();
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InteractiveMap;
