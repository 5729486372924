import firebase from "firebase/app";
import "firebase/firestore";

export const fetchMapData = async (visibleArea) => {
  try {
    const response = await fetch(
      "https://us-central1-theramalliance.cloudfunctions.net/getMap",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visibleArea),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error fetching map data:", error);
    throw error;
  }
};

export const fetchMapCoordinates =
  ({ x, y }) =>
  async () => {
    try {
      const db = firebase.firestore();

      const querySnapshot = await db
        .collection("mapCoordinates")
        .where("x", "==", x)
        .where("y", "==", y)
        .orderBy("updatedAt", "desc")
        .limit(1)
        .get();

      let coordinate = null;
      querySnapshot.forEach((doc) => {
        coordinate = { id: doc.id, ...doc.data() };
      });

      return coordinate;
    } catch (error) {
      console.error("Error fetching map coordinates:", error);
    }
  };

export const checkIfMapNeedsUpdate = async () => {
  try {
    const db = firebase.firestore();

    const serverMapSnapshot = await db
      .collection("serverMapInformation")
      .orderBy("mapCreatedAt", "desc")
      .limit(1)
      .get();

    if (serverMapSnapshot.empty) {
      console.log("No documents found in serverMapInformation.");
      return;
    }

    let serverName = null;
    let mapCreatedAt = null;

    serverMapSnapshot.forEach((doc) => {
      serverName = doc.data().serverName;
      mapCreatedAt = doc.data().mapCreatedAt.toDate();
    });

    const mapCoordinatesSnapshot = await db
      .collection("mapCoordinates")
      .where("serverName", "==", serverName)
      .orderBy("updatedAt", "desc")
      .limit(1)
      .get();

    let updatedAt = null;

    mapCoordinatesSnapshot.forEach((doc) => {
      updatedAt = doc.data().updatedAt.toDate();
    });

    if (updatedAt.getTime() <= mapCreatedAt.getTime()) {
      return null;
    } else {
      return "Map needs to be updated";
    }
  } catch (error) {
    console.error("Error checking if map needs update:", error);
  }
};

export const getPlayerVillages =
  ({ owner }) =>
  async () => {
    try {
      const db = firebase.firestore();

      const querySnapshot = await db
        .collection("mapCoordinates")
        .where("data.details.owner", "==", owner)
        .orderBy("updatedAt", "desc")
        .get();

      const uniqueVillagesMap = new Map();
      const villages = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const details = data?.data.details || {};

        if (details.owner === owner) {
          const { x, y, updatedAt } = data;

          const updatedAtDate = updatedAt.toDate();
          const key = `${x},${y}`;

          if (!uniqueVillagesMap.has(key)) {
            uniqueVillagesMap.set(key, {
              id: doc.id,
              ...data,
              updatedAt: updatedAtDate,
            });
            villages.push({ id: doc.id, ...data, updatedAt: updatedAtDate });
          } else {
            const existingVillage = uniqueVillagesMap.get(key);
            if (updatedAtDate > existingVillage.updatedAt) {
              uniqueVillagesMap.set(key, {
                id: doc.id,
                ...data,
                updatedAt: updatedAtDate,
              });
              const index = villages.findIndex(
                (v) => v.id === existingVillage.id
              );
              villages[index] = {
                id: doc.id,
                ...data,
                updatedAt: updatedAtDate,
              };
            }
          }
        }
      });

      return villages;
    } catch (error) {
      console.error("Error fetching villages:", error);
    }
  };

export const getAllianceVillages = (alliance, date) => async () => {
  try {
    const db = firebase.firestore();

    const querySnapshot = await db
      .collection("timeseriesMapInformation")
      .where("allianceTag", "==", alliance)
      .where("currentDate", "==", date)
      .get();

    const villages = [];
    querySnapshot.forEach((doc) => {
      villages.push({ id: doc.id, ...doc.data() });
    });

    return villages;
  } catch (error) {
    console.error("Error fetching map coordinates:", error);
    return [];
  }
};

export const getPlayersVillages = (playerName, date) => async () => {
  try {
    const db = firebase.firestore();

    const querySnapshot = await db
      .collection("timeseriesMapInformation")
      .where("playerName", "==", playerName)
      .where("currentDate", "==", date)
      .get();

    const villages = [];
    querySnapshot.forEach((doc) => {
      villages.push({ id: doc.id, ...doc.data() });
    });

    return villages;
  } catch (error) {
    console.error("Error fetching Villages:", error);
    return [];
  }
};
